import {Box, ClickAwayListener, Grow, Paper, Popper} from "@mui/material";

export const AppBarPopper = ({open, onClose, anchorEl, children}) => {
    return (
        <Popper
            open={open}
            anchorEl={anchorEl}
            role={undefined}
            placement="bottom-start"
            transition={true}
            disablePortal={true}
        >
            {({TransitionProps, placement}) => (
                <Grow
                    {...TransitionProps}
                    style={{
                        transformOrigin:
                            placement === "right top",
                    }}
                >
                    <Paper
                        elevation={12}
                        sx={{
                            p: 3,
                            width: 356
                        }}
                    >
                        <ClickAwayListener onClickAway={onClose}>
                            <Box>
                                {children}
                            </Box>
                        </ClickAwayListener>
                    </Paper>
                </Grow>
            )}
        </Popper>
    )
}