import {Badge} from "@mui/material";
import React from "react";
import MuseumIcon from "@mui/icons-material/Museum";
import {RoundedButton} from "../app-bar/RoundedButton";
import {useAppTranslation} from "../app/appContext";

export const AppBarAction = ({action}) => {
    const t = useAppTranslation()
    const actionIcon = {
        'museumSelector': <MuseumIcon />
    }

    if(action.badgeCount) {
        return (
            <RoundedButton
                onClick={action.callback}
                text={t(action.name)}
                Icon={
                    <Badge
                        color={action.badgeColor ? action.badgeColor: 'default'}
                        badgeContent={action.badgeCount ? action.badgeCount : 0}
                    >
                        {actionIcon[action.name]}
                    </Badge>
                }
                badge={false}
            />
        )
    } else {
        return (
            <RoundedButton
                onClick={action.callback}
                text={t(action.name)}
                Icon={actionIcon[action.name]}
                badge={false}
            />
        )
    }
}