import {Stack, Typography} from "@mui/material";
import React from "react";
import {LoginButton} from "../navigation/LoginButton";
import {GetAccessButton} from "../navigation/GetAccessButton";
import {AboutEkulturButton} from "../navigation/AboutEkulturButton";
import {useAppTranslation} from "../app/appContext";

export const EkulturPlaceHolderText = () => {
    const t = useAppTranslation()
    return (
        <Stack
            alignItems={"flex-start"}
            spacing={2}
        >
            <Typography
                variant={"h4"}
            >
                {t(
                    'placeholderHeader',
                    'Felles løsninger, kultur til alle',
                    {
                        ns: "header"
                    })
                }
            </Typography>
            <Typography>
                {t(
                    'placeholderText',
                    'Velkommen til eKultur, et digitalt økosystem med felles infrastruktur for forvaltning og formidling i museumssektoren.',
                    {
                        ns: "header"
                    })
                }
            </Typography>
            <Stack
                direction={{
                    xs: "column",
                    lg: "row"
                }}
                spacing={1}
            >
                <LoginButton />
                <GetAccessButton
                    variant={"outlined"}
                />
            </Stack>
            <AboutEkulturButton />
        </Stack>
    )
}