import {Stack, Typography, Grid2 as Grid} from "@mui/material";
import {ApplicationButton} from "./ApplicationButton";
import {buildUserApps} from "./appBuilder";
import {useGetUser} from "../user/useGetUser";
import {useGetApplications} from "./useGetApplications";
import {useGetMuseums} from "../museum/useGetMuseums";
import {buildUserMuseums} from "../museum/museumBuilder";
import {HomeButton} from "./HomeButton";

const sortApps = (a, b) => {
    if(a.serviceIcon && !b.serviceIcon){
        return -1
    } else if (!a.serviceIcon && b.serviceIcon){
        return 1
    } else {
        return a.title.localeCompare(b.title)
    }
}

export const ApplicationSwitcherMenu = () => {
    const user = useGetUser()
    const apps = useGetApplications()
    const museums = useGetMuseums()
    const userMuseums = buildUserMuseums(user, apps, museums, false)
    const userApps = buildUserApps(user, apps);

    const findAllApplications = () => {
        const museumApps = userMuseums.map(museum => museum.apps)
            .reduce((a, b) => [...a, ...b], [])

        return [...userApps, ...museumApps]
            .filter((app, index, array) => array.findIndex(a => a.id == app.id) == index)
    }


    return (
        <>
            <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
                sx={{
                    mb: 2
                }}
            >
                <Typography
                    variant={"h6"}
                    sx={{
                        fontSize: "1.125rem"
                    }}
                >
                    Dine applikasjoner
                </Typography>
                <HomeButton />
            </Stack>
            <Grid
                container={true}
                spacing={2}
            >
                {findAllApplications().filter(app => "be798b01-973b-4f75-95a2-607a560e0a6c" !== app.id)
                    .sort(sortApps)
                    .map(app => (
                    <ApplicationButton
                        key={app.id}
                        app={app}
                    />
                ))}
            </Grid>
        </>
    )
}