import React, {useRef, useState} from "react";
import {AppBarPopper} from "../app-bar/AppBarPopper";
import {ApplicationSwitcherMenu} from "./ApplicationSwitcherMenu";
import {RoundedButton} from "../app-bar/RoundedButton";
import {Apps} from "@mui/icons-material";

export const ApplicationSwitcher = () => {
    const button = useRef(null)
    const [open, setOpen] = useState(false)

    const buttonClickHandler = () => setOpen(p => !p)

    const closeHandler = event => {
        if(!button.current || !button.current.contains(event.target)) {
            setOpen(false)
        }
    }

    return (
        <>
            <RoundedButton
                ref={button}
                onClick={buttonClickHandler}
                text={"eKultur"}
                Icon={<Apps />}
            />
            <AppBarPopper
                open={open}
                anchorEl={button.current}
                onClose={closeHandler}
            >
                <ApplicationSwitcherMenu />
            </AppBarPopper>
        </>
    )
}