import {AppBar, Toolbar} from "@mui/material";

export const EkulturAppBar = ({authenticated, children}) => {
    return (
        <AppBar
            sx={theme => ({
                transition: theme.transitions.create(['background-color', 'color', 'transform'], {
                    duration: theme.transitions.duration.standard
                }),
                backgroundColor: theme => authenticated ? theme.palette.primary.main : "#FFFFFF",
                color: authenticated ? "default" : "black",
            })}
        >
            <Toolbar>
                {children}
            </Toolbar>
        </AppBar>
    )
}