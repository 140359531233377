import {Container, Stack, Toolbar} from "@mui/material";
import React from "react";

export const EkulturPlaceHolderContent = ({children}) => {
    return (
        <Container
            maxWidth={"lg"}
            disableGutters={false}
            sx={{
                height: "100dvh",
                overflowX: "hidden",
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
            }}
        >
            <Toolbar />
            <Stack
                direction={{
                    xs: "column-reverse",
                    lg: "row"
                }}
                justifyContent={"space-evenly"}
                alignItems={"center"}
                spacing={{
                    xs: 0,
                    lg: 32
                }}
            >
                {children}
            </Stack>
        </Container>
    )
}