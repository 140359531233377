import {Home} from "@mui/icons-material";
import {Button} from "@mui/material";
import {useGetApplications} from "./useGetApplications";

export const HomeButton = () => {
    const apps = useGetApplications()

    const clickHandler = () => {
        window.location.href = apps["be798b01-973b-4f75-95a2-607a560e0a6c"]?.url
    }

    return (
        <Button
            onClick={clickHandler}
            startIcon={<Home sx={{mr: -0.5}}/>}
            sx={{
                '&.header-MuiButton-text' : {
                    textTransform: "none !important"
                },
            }}
        >
            eKultur
        </Button>
    )
}