import {Container, Stack, Toolbar, useTheme} from "@mui/material";
import React from "react";

export const NoAccessPlaceHolderContent = ({children}) => {
    return (
        <Container
            maxWidth={"xl"}
            disableGutters={false}
            sx={{
                height: "100dvh",
                overflowX: "hidden",
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
            }}
        >
            <Toolbar />
            <Stack
                direction={{
                    xs: "column"
                }}
                justifyContent={"center"}
                alignItems={"center"}
                spacing={{
                    xs: 4,
                }}
            >
                {children}
            </Stack>
        </Container>
    )
}