export const buildUserMuseums = (user, apps, museums, addParameter=true) => {
    if(user && apps && museums && user.appAccess) {
        return user.appAccess.museums.map(museum => ({
            title: museums[museum.id] ? museums[museum.id].name : 'Museum name missing',
            uniqueId: museum.id.toString(),
            imageUrl: `https://${museums[museum.id].media}`,
            media: museums[museum.id].media,
            name: museums[museum.id] ? museums[museum.id].name : 'N/A',
            abbr: museums[museum.id].short_name || 'N/A',
            createdAt: museums[museum.id].created,
            url: addParameter ? `/?museum_id=${museum.id.toString()}` : '/',
            theme: {
                primaryColor: museums[museum.id].primary_color || "#d94353",
                lightColor: museums[museum.id].light_color || "#ee7e8f",
                darkColor: museums[museum.id].dark_color || "#ae3552"
            },
            apps: museum.applications.map(app => ({
                ...app,
                title: apps[app.id].title,
                url: addParameter ? `${apps[app.id].url}?museum_id=${museum.id}` : apps[app.id].url,
                icon: apps[app.id].icon_url ? null : apps[app.id].icon,
                imageUrl: apps[app.id].icon_url,
                theme: {
                    primaryColor: apps[app.id].theme.primary_color,
                    lightColor: apps[app.id].theme.light_color,
                    darkColor: apps[app.id].theme.dark_color
                },
                directUrl: apps[app.id].direct_url,
                openInNewTab: apps[app.id].open_in_new_tab,
                serviceIcon: apps[app.id].service_icon,
                serviceApplication: apps[app.id].service_application,
                implicitAccess: apps[app.id].implicit_access,
                initials: apps[app.id].initials
            })),
            type: museums[museum.id].type
        }))
    } else {
        return [];
    }
}