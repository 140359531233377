import React from 'react';
import './MuiClassNameSetup'
import {HeaderWhenAuthenticated} from "../app-header/HeaderWhenAuthenticated";
import {HeaderWhenAnonymous} from "../app-header/HeaderWhenAnonymous";
import {StyledEngineProvider} from '@mui/material/styles';
import {createTheme, ThemeProvider} from "@mui/material";
import {useUpdateUserData} from "../user/useUpdateUserData";
import {EkulturAppBar} from "../app-bar/EkulturAppBar";
import {DrawerButton} from "../app-header/DrawerButton";
import {EkulturPlaceHolder} from "../placeholder/EkulturPlaceHolder";
import {NoAccessPlaceHolder} from "../placeholder/NoAccessPlaceHolder";


export const App = ({
                        mobileOpenHandler,
                        className,
                        themeOptions,
                        mobileOpen = false,
                        loggedIn = false,
                        search = null,
                        actions = [],
                        onRender,
                        user,
                        museums,
                        apps,
                        alwaysShowHamburger = false,
                        showNoAccessPlaceholder = false
                    }) => {
    useUpdateUserData({
        user: user,
        museums: museums,
        apps: apps
    })

    const loggedInOrAnonymous = {
        true: (
            <HeaderWhenAuthenticated
                search={search}
                actions={actions}
                DrawerButton={(
                    <DrawerButton
                        className={className}
                        mobileOpenHandler={mobileOpenHandler}
                        mobileOpen={mobileOpen}
                        alwaysShowHamburger={alwaysShowHamburger}
                    />
                )}
            />
        ),
        false: (
            <HeaderWhenAnonymous/>
        )
    }

    return (
        <StyledEngineProvider injectFirst={true}>
            <ThemeProvider theme={createTheme(themeOptions)}>
                <div ref={onRender}/>
                <EkulturAppBar
                    authenticated={loggedIn}
                >
                    {loggedInOrAnonymous[loggedIn]}
                </EkulturAppBar>
                <EkulturPlaceHolder
                    show={!loggedIn}
                />
                <NoAccessPlaceHolder
                    show={showNoAccessPlaceholder && loggedIn}
                />
            </ThemeProvider>
        </StyledEngineProvider>
    )
}
