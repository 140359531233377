import {Box, Divider} from "@mui/material";
import {ProfileInfo} from "./ProfileInfo";
import {ApplicationInfo} from "./ApplicationInfo";
import {Actions} from "./Actions";

export const UserProfileMenu = () => {

    return (
        <Box>
            <ProfileInfo />
            <Divider
                sx={{
                    ml: -3,
                    mr: -3,
                    mt: 3,
                    mb: 3
                }}
            />
            <ApplicationInfo />
            <Divider
                sx={{
                    ml: -3,
                    mr: -3,
                    mt: 3,
                    mb: 3
                }}
            />
            <Actions />
        </Box>
    )
}