import {Link, Stack, Typography} from "@mui/material";
import React from "react";
import {useAppTranslation} from "../app/appContext";
import {useAppTitle} from "../app-header/useAppTitle";
import {useIsEkulturAdmin} from "../user/useIsEkulturAdmin";
import {useEkulturAdminUrl} from "../user/useEkulturAdminUrl";
import {Trans} from "react-i18next";

export const NoAccessPlaceHolderText = () => {
    const t = useAppTranslation()
    const appTitle = useAppTitle()
    const isEkulturAdmin = useIsEkulturAdmin()
    const eKulturAdminUrl = useEkulturAdminUrl()

    const text = {
        true: (
            <Typography>
                <Trans t={t} i18nKey="noAccessButAdminPlaceholderText" ns={"header"}>
                    Som eKultur-administrator har du mulighet til å redigere brukertilganger i <Link href={eKulturAdminUrl}>admin-applikasjonen.</Link>
                </Trans>
            </Typography>
        ),
        false: (
            <Typography>
                {t(
                    'noAccessPlaceholderText',
                    'Kontakt din nærmeste leder eller eKultur-administrator dersom du ønsker brukertilgang til applikasjonen.',
                    {
                        ns: 'header'
                    }
                )}
            </Typography>
        )
    }

    return (
        <Stack
            alignItems={"flex-start"}
            spacing={2}
        >
            <Typography
                variant={"h4"}
            >
                {t(
                    'noAccessPlaceholderHeader',
                    'Du har ikke tilgang til {{appTitle}}',
                    {
                        ns: 'header',
                        appTitle: appTitle
                    }
                )}
            </Typography>
            {text[isEkulturAdmin]}
        </Stack>
    )
}