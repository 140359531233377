import {useAppDispatch, useAppState, USER_RECEIVED} from "../app/appContext";
import {useEffect} from "react";
import {kitFetch} from "@ekultur/fetch";

export const useGetUser = () => {
    const {user} = useAppState()
    const dispatch = useAppDispatch()

    useEffect(
        () => {
            if(null === user) {
                kitFetch(`/authz/authorize/userData`)
                    .then(json => {
                        dispatch({
                            type: USER_RECEIVED,
                            user: {
                                ...json,
                                theme: {
                                    primaryColor: "#d94353",
                                    lightColor: "#ee7e8f",
                                    darkColor: "#ae3552"
                                }
                            }
                        })
                    })
                    .catch(error => {
                        console.error(error)
                    })
            }
        }, []
    )

    return user
}