import {Link} from "@mui/material";
import React, {forwardRef} from "react";

export const AppTitle = forwardRef(({children}, ref) => {
    return (
        <Link
            variant={"h6"}
            ref={ref}
            underline={"none"}
            color={"inherit"}
            href={"/"}
            sx={{
                '&:hover': {
                    cursor: 'pointer'
                },
            }}
        >
            {children}
        </Link>
    )
})