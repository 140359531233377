import {MUSEUMS_RECEIVED, useAppDispatch, useAppState} from "../app/appContext";
import {useEffect} from "react";
import {kitFetch} from "@ekultur/fetch";

export const useGetMuseums = () => {
    const {museums} = useAppState()
    const dispatch = useAppDispatch()

    useEffect(
        () => {
            if(null === museums) {
                kitFetch('/museum-api/museums')
                    .then(json => {
                        dispatch({
                            type: MUSEUMS_RECEIVED,
                            museums: json.museums.reduce((accumulator, item) => ({
                                ...accumulator,
                                [item.id]: item
                            }), {})
                        })
                    })
                    .catch(error => {
                        console.error(error)
                    })
            }
        }, []
    )

    return museums
}