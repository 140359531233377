import React, {createContext, useContext, useReducer} from "react";
import {useTranslation} from "react-i18next";
import {ProgressIndicator} from "./ProgressIndicator";

export const MUSEUMS_RECEIVED = 'museumsReceived';
export const APPS_RECEIVED = 'appsReceived';
export const USER_RECEIVED = 'userReceived'

const AppStateContext = createContext(undefined);
const AppDispatchContext = createContext(undefined);
const AppTranslationContext = createContext(undefined);

const initialState = {
    museums: null,
    apps: null,
    user: null,
    appId: null,
    version: "1.0",
    buildDate: null,
    logoutHandler: null,
    loginHandler: null,
}

const appReducer = (state, action) => {
    console.debug(action);
    switch (action.type) {
        case MUSEUMS_RECEIVED:
            return {
                ...state,
                museums: action.museums
            }
        case APPS_RECEIVED:
            return {
                ...state,
                apps: action.apps
            }
        case USER_RECEIVED:
            return {
                ...state,
                user: action.user
            }
        default:
            throw new Error(`Unhandled action type ${action.type}`)
    }
}

export const AppProvider = ({
                                user,
                                apps,
                                museums,
                                appId,
                                logoutHandler,
                                loginHandler,
                                version = "1.0",
                                buildDate = null,
                                children
                            }) => {
    const [state, dispatch] = useReducer(
        appReducer,
        {
            ...initialState,
            user: user,
            apps: apps,
            museums: museums,
            appId: appId,
            version: version,
            buildDate: buildDate,
            logoutHandler: logoutHandler,
            loginHandler: loginHandler,
        },
        undefined
    )

    const {t, ready} = useTranslation(['navigation', 'header'], {useSuspense: false});

    if (ready) {
        return (
            <AppTranslationContext.Provider value={t}>
                <AppStateContext.Provider value={state}>
                    <AppDispatchContext.Provider value={dispatch}>
                        {children}
                    </AppDispatchContext.Provider>
                </AppStateContext.Provider>
            </AppTranslationContext.Provider>
        )
    } else {
        return (
            <ProgressIndicator/>
        )
    }

}

export const useAppState = () => {
    const context = useContext(AppStateContext);
    if (undefined === context) {
        throw new Error(`useAppState must be used witin an AppProvider`);
    } else {
        return context;
    }
}

export const useAppDispatch = () => {
    const context = useContext(AppDispatchContext);
    if (undefined === context) {
        throw new Error(`useAppDispatch must be used within an AppProvider`);
    } else {
        return context;
    }
}

export const useAppTranslation = () => {
    const context = useContext(AppTranslationContext)

    if(undefined === context) {
        throw new Error('useAppTranslation must be used within an AppProvider')
    } else {
        return context
    }
}