export const buildUserApps = (user, apps) => {
    const implicitApps = Object.keys(apps).filter(appId => apps[appId].implicit_access)
        .map(appId => ({
            ...apps[appId],
            displayTitle: apps[appId].display_title,
            icon: apps[appId].icon_url ? null : apps[appId].icon,
            imageUrl: apps[appId].icon_url,
            theme: {
                primaryColor: apps[appId].theme.primary_color,
                lightColor: apps[appId].theme.light_color,
                darkColor: apps[appId].theme.dark_color
            },
            directUrl: apps[appId].direct_url,
            openInNewTab: apps[appId].open_in_new_tab,
            serviceIcon: apps[appId].service_icon,
            serviceApplication: apps[appId].service_application,
            implicitAccess: apps[appId].implicit_access
        }))

    if(user && apps && user.appAccess) {
        const userApps =  user.appAccess.apps.map(app => ({
            ...app,
            title: apps[app.id].title,
            displayTitle: apps[app.id].display_title,
            url: apps[app.id].url,
            icon: apps[app.id].icon_url ? null : apps[app.id].icon,
            imageUrl: apps[app.id].icon_url,
            theme: {
                primaryColor: apps[app.id].theme.primary_color,
                lightColor: apps[app.id].theme.light_color,
                darkColor: apps[app.id].theme.dark_color
            },
            directUrl: apps[app.id].direct_url,
            openInNewTab: apps[app.id].open_in_new_tab,
            serviceIcon: apps[app.id].service_icon,
            serviceApplication: apps[app.id].service_application,
            implicitAccess: apps[app.id].implicit_access,
            initials: apps[app.id].initials
        }))

        return [
            ...userApps,
            ...implicitApps
        ]
    } else {
        return implicitApps
    }
}

export const buildCurrentApp = (apps, appId, version, buildDate)  => {
    if(apps && appId) {
        return {
            ...apps[appId],
            imageUrl: apps[appId].icon_url,
            displayTitle: apps[appId].display_title,
            icon: null,
            version: version,
            updatedAt: buildDate || "",
            directUrl: apps[appId].direct_url,
            openInNewTab: apps[appId].open_in_new_tab,
            serviceIcon: apps[appId].service_icon,
            serviceApplication: apps[appId].service_application,
            implicitAccess: apps[appId].implicit_access,
            initials: apps[appId].initials
        }
    } else {
        return {}
    }
}