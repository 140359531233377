import {Box, Zoom} from "@mui/material";

export const Illustration = ({
     top,
     left,
     width,
     height,
     src,
     alt,
     boxShadow,
     backgroundColor = "#F3FAFA",
     borderRadius = "8px",
     transitionDelay = "0ms"
 }) => {
    return (
        <Zoom
            in={true}
            style={{
                transitionDelay: transitionDelay
            }}
        >
            <Box
                sx={{
                    position: "absolute",
                    top: top,
                    left: left,
                    boxShadow: boxShadow,
                    borderRadius: borderRadius,
                    background: backgroundColor,
                    opacity: "0.97"
                }}
            >
                <img
                    src={src}
                    alt={alt}
                    width={width}
                    height={height}
                />
            </Box>
        </Zoom>
    )
}