import {App} from "./App";
import {AppProvider} from "./appContext";
import React, {useState} from "react";

export const Demo = () => {

    const [loggedIn, setLoggedIn] = useState(false)
    const [searchValue, setSearchValue] = useState("")

    const user = {
        name: "Donald",
        src: "",
        email: "donald.duck@kulturit.no",
        appAccess: {
            apps: [
                // {
                //     "id": "2cdc3799-bbc1-4a46-9529-2bdd7f3e0694",
                //     "role": {
                //         "name": "Admin",
                //         "id": 1
                //     }
                // },
                {
                    "id": "7cc29642-97e9-416b-8f3e-1b5dcba1ee0e",
                    "role": {
                        "name": "Admin",
                        "id": 1
                    }
                },
                {
                    "id": "be798b01-973b-4f75-95a2-607a560e0a6c",
                    "role": {
                        "name": "Admin",
                        "id": 1
                    }
                },
                {
                    "id": "12d07b58-2151-4aa6-96a1-3767583c798c",
                    "role": {
                        "name": "Admin",
                        "id": 1
                    }
                },
                {
                    "id": "796fc36a-6637-47aa-b83e-e61d3b8eeca3",
                    "role": {
                        "name": "Admin",
                        "id": 1
                    }
                },
                {
                    "id": "43611eac-92f1-405a-b50d-e0e7d8e8195b",
                    "role": {
                        "name": "Admin",
                        "id": 1
                    }
                },
                {
                    "id": "0f99554d-4d53-4ce6-ada5-6189f3abdf52",
                    "role": {
                        'name': 'User',
                        "id": 3
                    }
                }
            ],
            museums: []
        }

    }

    return (
        <AppProvider
            user={user}
            museums={null}
            apps={null}
            appId={"2cdc3799-bbc1-4a46-9529-2bdd7f3e0694"}
            loginHandler={() => setLoggedIn(true)}
            logoutHandler={() => setLoggedIn(false)}
        >
            <App
                showNoAccessPlaceholder={true}
                loggedIn={loggedIn}
                appId={"2cdc3799-bbc1-4a46-9529-2bdd7f3e0694"}
                search={{
                    handler: () => console.debug('search'),
                    value: searchValue,
                    onChange: val => setSearchValue(val)
                }}
                actions={[
                    {
                        name: "museumSelector",
                        callback: () => console.log('yolo'),
                    }
                ]}
            />
        </AppProvider>
    )
}