import {APPS_RECEIVED, useAppDispatch, useAppState} from "../app/appContext";
import {useEffect} from "react";
import {kitFetch} from "@ekultur/fetch";

export const useGetApplications = () => {
    const {apps} = useAppState()
    const dispatch = useAppDispatch()

    useEffect(
        () => {
            if(null === apps) {
                kitFetch('/app-registry/apps/')
                    .then(json => {
                        dispatch({
                            type: APPS_RECEIVED,
                            apps: json.map(app => ({
                                ...app,
                                serviceIcon: app.service_icon,
                                serviceApplication: app.service_application,
                                imageUrl: app.icon_url,
                                displayTitle: app.display_title,
                                directUrl: app.direct_url,
                                openInNewTab: app.open_in_new_tab,
                                theme: {
                                    ...app.theme,
                                    primaryColor: app.theme.primary_color,
                                    lightColor: app.theme.light_color,
                                    darkColor: app.theme.dark_color
                                },
                                implicitAccess: app.implicit_access
                            }))
                                .reduce((accumulator, item) => ({
                                ...accumulator,
                                [item.id]: item
                            }), {})
                        })
                    })
                    .catch(error => {
                        console.error(error)
                    })
            }
        }, []
    )

    return apps
}

