import React from "react";
import {useGetApplications} from "../application/useGetApplications";
import {buildCurrentApp} from "../application/appBuilder";
import {Fade, Hidden, Stack} from "@mui/material";
import {AppBarAction} from "./AppBarAction";
import {AppTitle} from "./AppTitle";
import {SearchBox} from "../search/SearchBox";
import {ApplicationSwitcher} from "../application/ApplicationSwitcher";
import {UserProfile} from "../user/UserProfile";
import {useAppState} from "../app/appContext";

export const HeaderWhenAuthenticated = ({DrawerButton, search, actions}) => {
    const {
        version,
        buildDate,
        appId
    } = useAppState()
    const apps = useGetApplications();
    const currentApp = buildCurrentApp(apps, appId, version, buildDate)

    return (
        <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            sx={{
                width: "100%",
            }}
        >
            <Stack
                direction={"row"}
                alignItems={"center"}
                spacing={1}
                sx={{
                    ml: -1.5
                }}
            >
                {DrawerButton}
                <Hidden smDown={Boolean(null !== search)}>
                    <Fade
                        in={true}
                    >
                        <AppTitle>
                            {currentApp.displayTitle ? currentApp.displayTitle : currentApp.title}
                        </AppTitle>
                    </Fade>
                </Hidden>
                <SearchBox
                    search={search}
                />
            </Stack>
            <Stack
                direction={"row"}
                alignItems={"center"}
                spacing={1}
            >
                {actions.map(action =>
                    <AppBarAction key={action.name} action={action}/>
                )}
                <ApplicationSwitcher/>
                <UserProfile/>

            </Stack>

        </Stack>
    )
}
