import {Avatar, IconButton} from "@mui/material";
import React, {forwardRef} from "react";
import {useGetUser} from "./useGetUser";

export const UserProfileButton = forwardRef(({onClick}, ref) => {
    const user = useGetUser()
    return (
        <IconButton
            ref={ref}
            onClick={onClick}
            sx={{
                p: 0
            }}
        >
            <Avatar
                alt={user?.name}
                src={user?.imageUrl}
            />
        </IconButton>

    )
})