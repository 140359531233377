import {Box, Fade, Toolbar} from "@mui/material";
import React from "react";
import {EkulturPlaceHolderContent} from "./EkulturPlaceHolderContent";
import {EkulturPlaceHolderText} from "./EkulturPlaceHolderText";
import {EkulturPlaceHolderImage} from "./EkulturPlaceHolderImage";

export const EkulturPlaceHolder = ({show}) => {
    if (show) {
        return (
            <Fade
                in={true}
                timeout={{
                    enter: 500,
                    leave: 500
                }}
                style={{
                    transitionDelay: "1000ms"
                }}
            >
                <Box
                    sx={{
                        backgroundImage: "url(https://ems.dimu.org/image/019EE5EDQsji2?dimension=3072)",
                    }}
                >
                    <EkulturPlaceHolderContent>
                        <EkulturPlaceHolderText />
                        <EkulturPlaceHolderImage />
                    </EkulturPlaceHolderContent>
                </Box>
            </Fade>
        )
    } else {
        return null
    }

}