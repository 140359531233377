import {Button} from "@mui/material";
import React from "react";
import {useAppState, useAppTranslation} from "../app/appContext";

export const LoginButton = () => {
    const {loginHandler} = useAppState()
    const t = useAppTranslation()
    return (
        <Button
            variant={"contained"}
            onClick={loginHandler}
        >
            {t('loginButton', "Logg inn")}
        </Button>
    )
}