import {IconButton} from "@mui/material";
import {Menu} from "@mui/icons-material";

export const DrawerButton = ({mobileOpen, mobileOpenHandler, className, alwaysShowHamburger = false}) => {
    const clickHandler = () => mobileOpenHandler(!mobileOpen)

    if (className?.hamburger) {
        return (
            <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={clickHandler}
                className={className?.hamburger}
                size="large"
            >
                <Menu/>
            </IconButton>
        )
    } else if (alwaysShowHamburger) {
        return (
            <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={clickHandler}
                size="large"
            >
                <Menu/>
            </IconButton>
        )
    } else if (!mobileOpenHandler) {
        return null
    } else {
        return (
            <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={() => clickHandler()}
                sx={{
                    display: {
                        sm: 'none'
                    }
                }}
                size="large"
            >
                <Menu/>
            </IconButton>
        )
    }
}