import React, {forwardRef} from "react";
import {ButtonBase, Stack, Typography, Hidden, IconButton} from "@mui/material";

export const RoundedButton = forwardRef(({Icon, text, onClick}, ref) => {
    return (
        <>
            <Hidden mdDown={true}>
                <Stack
                    ref={ref}
                    onClick={onClick}
                    component={ButtonBase}
                    direction={"row"}
                    spacing={1}
                    alignItems={"center"}
                    justifyContent={"flex-start"}
                    sx={{
                        backgroundColor: 'primary.dark',
                        borderRadius: "30px",
                        paddingTop: 1,
                        paddingLeft: 2,
                        paddingRight: 2,
                        paddingBottom: 1,
                        color: "white"
                    }}
                >
                    {Icon}
                    <Typography>
                        {text}
                    </Typography>
                </Stack>
            </Hidden>
            <Hidden mdUp={true}>
                <IconButton
                    ref={ref}
                    onClick={onClick}
                    sx={{
                        color: "inherit"
                    }}
                >
                    {Icon}
                </IconButton>
            </Hidden>
        </>
    )
})