import {Button, Stack} from "@mui/material";
import {LiveHelpOutlined, Logout} from "@mui/icons-material";
import {useAppState, useAppTranslation} from "../app/appContext";


export const Actions = () => {
    const {
        logoutHandler
    } = useAppState()
    const t = useAppTranslation()

    return (
        <Stack
            direction={"row"}
            justifyContent={"center"}
            spacing={4}
        >
            <Button
                startIcon={<Logout />}
                onClick={logoutHandler}
                size={"small"}
            >
                {t('logoutButton', 'Logg ut')}
            </Button>
            <Button
                startIcon={<LiveHelpOutlined />}
                onClick={() => window.open('https://kulturit.org/kontaktoss', '_blank')}
                size={"small"}
            >
                {t('helpButton', "Hjelp")}
            </Button>
        </Stack>
    )
}