import React from "react";
import {Container, Fade, Stack} from "@mui/material";
import {AppTitle} from "./AppTitle";
import {LoginButton} from "../navigation/LoginButton";
import {GetAccessButton} from "../navigation/GetAccessButton";
import {useAppTitle} from "./useAppTitle";


export const HeaderWhenAnonymous = () => {
    const appTitle = useAppTitle()

    return (
        <Container
            maxWidth={"lg"}
            disableGutters={true}
        >
            <Stack
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
            >
                <AppTitle>
                    {appTitle}
                </AppTitle>
                <Fade
                    in={true}
                    style={{
                        transitionDelay: "1000ms"
                    }}
                >
                    <Stack
                        direction={"row"}
                        alignItems={"center"}
                        spacing={2}
                    >
                        <GetAccessButton />
                        <LoginButton />
                    </Stack>
                </Fade>
            </Stack>
        </Container>
    )
}

