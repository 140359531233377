import {AppServiceIcon} from "@ekultur/app-icons";
import React from "react";

export const ServiceIconPreview = ({serviceIcon, size = 32}) => {
    return (
        <AppServiceIcon
            appName={serviceIcon}
            size={size}
        />
    )
}