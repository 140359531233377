import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import {App} from './app/App';
import './app/i18n';
import {AppProvider} from "./app/appContext";
import {createRoot} from "react-dom/client";
import {Demo} from "./app/Demo";

let root = null

window.renderDemo = () => {
    const container = document.getElementById("container")
    if(!root) {
        root = createRoot(container)
    }
    root.render(
        <Demo />
    )
}

window.renderHeader = (args) => {
    const container = document.getElementById(args.containerId)
    if(!root) {
        root = createRoot(container)
    }
    root.render(
        <AppProvider
            user={args.user ? args.user : null}
            museums={args.museums ? args.museums : null}
            apps={args.apps ? args.apps : null}
            appId={args.appId ? args.appId : null}
            version={args.version ? args.version : "1.0"}
            buildDate={args.buildDate ? args.buildDate : null}
            logoutHandler={args.logoutHandler ? args.logoutHandler : null}
            loginHandler={args.loginHandler ? args.loginHandler : null}
        >
            <App {...args}/>
        </AppProvider>
    )
}

window.unmountHeader = containerId => {
    if(root) {
        root.unmount()
        root = null
    }
}