import React from "react";
import {AppHeaderSearch} from "./AppHeaderSearch";
import {useAppTranslation} from "../app/appContext";

export const SearchBox = ({search}) => {
    const t = useAppTranslation()
    if(search) {
        return (
            <AppHeaderSearch
                onSearch={search.handler}
                placeholder={search.placeHolder ? search.placeHolder : t('search', "Søk...")}
                value={search.value ? search.value : null}
                onChange={search.onChange ? search.onChange : null}
            />
        )
    } else {
        return null
    }

}