import {useAppState} from "../app/appContext";
import {useGetApplications} from "../application/useGetApplications";

export const useAppTitle = () => {
    const {
        appId
    } = useAppState()
    const apps = useGetApplications();

    if (apps && appId) {
        return apps[appId].display_title ? apps[appId].display_title : apps[appId].title
    } else {
        return ''
    }
}