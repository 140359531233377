import {OpenInNew} from "@mui/icons-material";
import {Button} from "@mui/material";
import React from "react";
import {useAppTranslation} from "../app/appContext";

export const AboutEkulturButton = () => {
    const t = useAppTranslation()
    return (
        <Button
            endIcon={<OpenInNew />}
            href={"https://kulturit.org/ekultur"}
            target={"_blank"}
            sx={{
                '&.header-MuiButton-text' : {
                    textTransform: "none !important"
                }
            }}
        >
            {t('moreAboutEkulturButton', "Les mer om eKultur")}
        </Button>
    )
}