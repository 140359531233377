import {Button} from "@mui/material";
import React from "react";
import {useAppTranslation} from "../app/appContext";

export const GetAccessButton = ({variant="text"}) => {
    const t = useAppTranslation()
    return (
        <Button
            href={`${window._env_.REACT_APP_LOGIN_HOST}/signup`}
            variant={variant}
        >
            {t('getAccessButton', "Få tilgang")}
        </Button>
    )
}