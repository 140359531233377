import React, {useState} from 'react';
import {alpha} from '@mui/material/styles';
import {Search as SearchIcon} from '@mui/icons-material';
import {IconButton, Input, InputAdornment, styled} from "@mui/material";


const SearchDiv = styled("div")(({theme}) => ({
    search: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        marginLeft: 0,
        marginRight: theme.spacing(2),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(1),
            width: 'auto',
        },
    },
}))

export const AppHeaderSearch = ({onSearch, value=null, onChange=null, placeholder=null}) => {
    const [searchString, setSearchString] = useState("");

    const changeHandler = event => {
        const newValue = event.target.value;
        console.debug({newValue})
        if(onChange) {
            onChange(newValue);
        } else {
            setSearchString(newValue);
        }
    }

    const searchHandler = () => {
        if(!onSearch) {
            throw new Error('required onSearch prop is not set. ')
        } else {
            onSearch(getValue());
        }
    }

    const keyPressHandler = event => {
        if('Enter' === event.key) {
            searchHandler();
        }
    }

    const getValue = () => value || searchString;

    return (
        <SearchDiv>
            <div>
            <Input
                value={getValue()}
                onChange={changeHandler}
                placeholder={placeholder || "Search..."}
                disableUnderline={true}
                onKeyUp={keyPressHandler}
                sx={theme => ({
                    color: 'inherit',
                    paddingLeft: "1em",
                    borderRadius: theme.shape.borderRadius,
                    backgroundColor: theme => alpha(theme.palette.common.white, 0.15),
                    '&:hover': {
                        backgroundColor: theme => alpha(theme.palette.common.white, 0.25),
                    },
                    input: theme => ({
                        padding: theme.spacing(1, 1, 1, 0),
                        transition: theme.transitions.create('width'),
                        width: '100%',
                        [theme.breakpoints.up('sm')]: {
                            width: '12ch',
                            '&:focus': {
                                width: '20ch',
                            },
                        },
                    })
                })}
                endAdornment={
                    <InputAdornment position={"end"} sx={{color: "inherit"}}>
                        <IconButton
                            onClick={searchHandler}
                            size="large"
                            sx={{color: "inherit"}}
                        >
                            <SearchIcon />
                        </IconButton>
                    </InputAdornment>
                }
            />
            </div>
        </SearchDiv>
    )
}