import {ServiceIconPreview} from "./ServiceIconPreview";
import {IconPreview} from "./IconPreview";
import React from "react";

export const ApplicationIcon = ({serviceIcon, serviceApplication, primaryColor, initials, title=null, size = 32}) => {

    if (serviceApplication) {
        return (
            <ServiceIconPreview
                serviceIcon={serviceIcon}
                size={size}
            />

        )
    } else {
        return (
            <IconPreview
                initials={initials || title?.slice(0, 2) || ""}
                primaryColor={primaryColor}
                size={size}
            />
        )
    }
}