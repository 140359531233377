import {AppIcon} from "@ekultur/app-icons";
import React from "react";

export const IconPreview = ({initials="", primaryColor, size=32}) => {
    return (
        <AppIcon
            appNameAbbreviation={initials}
            color={primaryColor}
            size={size}
        />
    )
}