import {Box, Paper, Typography} from "@mui/material";
import {useAppTitle} from "../app-header/useAppTitle";
import {AboutEkulturButton} from "../navigation/AboutEkulturButton";
import {useAppTranslation} from "../app/appContext";
import {useIsEkulturAdmin} from "../user/useIsEkulturAdmin";


export const NoAccessPlaceHolderFooter = () => {
    const appTitle = useAppTitle()
    const t = useAppTranslation()
    const isEkulturAdmin = useIsEkulturAdmin()

    if(isEkulturAdmin) {
        return null
    } else {
        return (
            <Box
                sx={{
                    position: "relative",
                }}
            >
                <Paper
                    variant={"outlined"}
                    sx={{
                        p: 2,
                        background: "rgba(0, 0, 0, 0)"
                    }}
                >
                    <Typography
                        variant={"body2"}
                    >
                        {t(
                            'noAccessPlaceholderFooter',
                            '{{appTitle}} er en del av eKultur, et digitalt økosystem med felles infrastruktur for forvaltning og formidling i museumssektoren.',
                            {
                                ns: 'header',
                                appTitle: appTitle
                            }
                        )}
                    </Typography>
                    <AboutEkulturButton />
                </Paper>
            </Box>
        )
    }
}