import {APPS_RECEIVED, MUSEUMS_RECEIVED, useAppDispatch, USER_RECEIVED} from "../app/appContext";
import {useEffect} from "react";

export const useUpdateUserData = ({user, museums, apps}) => {
    const appDispatch = useAppDispatch()

    useEffect(
        () => {
            if(user && Object.keys(user).length > 0) {
                appDispatch({
                    type: USER_RECEIVED,
                    user: user
                })
            }
        }, [user, appDispatch]
    )

    useEffect(
        () => {
            if(museums && Object.keys(museums).length > 0) {
                appDispatch({
                    type: MUSEUMS_RECEIVED,
                    museums: museums
                })
            }
        }, [museums, appDispatch]
    )

    useEffect(
        () => {
            if(apps && Object.keys(apps).length > 0) {
                appDispatch({
                    type: APPS_RECEIVED,
                    apps: apps
                })
            }
        }, [apps, appDispatch]
    )
}